import React from 'react';
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  ReferenceArrayInput,
  SelectArrayInput,
  SimpleForm,
  TextField,
  TextInput
} from 'react-admin';

import List from './List';
import { handleRelated } from './utils';

export const CategoryList = props => (
  <List {...props} sort={{ field: 'updated_at', order: 'DESC' }}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <DateField source="created_at" showTime />
      <DateField source="updated_at" showTime />
    </Datagrid>
  </List>
);

export const CategoryEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <ReferenceArrayInput label="Sectors" source="sectors" reference="sectors">
        <SelectArrayInput optionText="name" format={handleRelated} />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);

export const CategoryCreate = props => (
  <Create redirect="list" {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <ReferenceArrayInput label="Sectors" source="sectors" reference="sectors">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);
