import React from 'react';
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  FileField,
  FileInput,
  SelectField,
  SelectInput,
  Show,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  useRecordContext
} from 'react-admin';
import JSONPretty from 'react-json-pretty';
import JSONPrettyMon from 'react-json-pretty/dist/monikai';

import List from './List';
import { S3FileField } from './fields';

export const Privacy = [
  { id: 1, name: 'Personal' },
  { id: 2, name: 'Organisation' },
  { id: 3, name: 'Public' }
];

export const AssetType = [
  { id: 'image', name: 'Image' },
  { id: 'video', name: 'Video' },
  { id: 'sound', name: 'Sound' },
  { id: 'document', name: 'Document' }
];

export const AssetList = props => (
  <List {...props} sort={{ field: 'updated_at', order: 'DESC' }}>
    <Datagrid rowClick="show">
      <TextField source="media.original_filename" sortable={false} />
      <SelectField source="privacy" choices={Privacy} />
      <SelectField source="type" choices={AssetType} />
      <DateField source="created_at" showTime />
      <DateField source="updated_at" showTime />
    </Datagrid>
  </List>
);

export const AssetShow = props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Summary">
        <TextField source="media.original_filename" />
        <SelectField source="privacy" choices={Privacy} />
        <SelectField source="type" choices={AssetType} />
        <S3FileField source="media" />
      </Tab>
      <Tab label="JSON Setting">
        <JSONSetting />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const AssetEdit = props => (
  <Edit redirect="show" {...props}>
    <SimpleForm>
      <SelectInput source="type" choices={AssetType} />
      <SelectInput source="privacy" choices={Privacy} />
      <FileInput source="media" label="Asset">
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Edit>
);

export const AssetCreate = props => (
  <Create redirect="show" {...props}>
    <SimpleForm>
      <SelectInput source="type" choices={AssetType} />
      <SelectInput source="privacy" choices={Privacy} />
      <FileInput source="media" label="Asset">
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
);

const JSONSetting = () => {
  const asset = useRecordContext();
  return <JSONPretty data={trimAssetSetting(asset)} theme={JSONPrettyMon} />;
};

const trimAssetSetting = ({ id, media }) => ({
  id,
  media: {
    id: media.id,
    filename: media.filename,
    original_filename: media.original_filename
  }
});
