import React from 'react';
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  SimpleForm,
  TextField,
  TextInput
} from 'react-admin';

import List from './List';

export const MetaList = props => (
  <List {...props} sort={{ field: 'name', order: 'ASC' }}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <DateField source="created_at" showTime />
      <DateField source="updated_at" showTime />
    </Datagrid>
  </List>
);

export const MetaEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
);

export const MetaCreate = props => (
  <Create redirect="list" {...props}>
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);
