import { Delete as DeleteIcon } from '@mui/icons-material';
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import {
  useDataProvider,
  useRecordContext,
  useRefresh,
  useNotify
} from 'react-admin';

const DeleteSpaceDeviceButton = ({ spaceId }) => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = async () => {
    try {
      await dataProvider.delete('space-devices', {
        id: `${spaceId}/${record.id}`
      });
      notify('Device deleted from space');
      refresh();
    } catch (err) {
      notify('Error deleting device from space', { type: 'error' });
    }
  };

  return (
    <Button className="ra-delete-button" onClick={handleClick} size="small">
      <DeleteIcon /> Delete
    </Button>
  );
};

DeleteSpaceDeviceButton.propTypes = {
  record: PropTypes.object
};

export default DeleteSpaceDeviceButton;
