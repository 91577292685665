import {
  Business as BusinessIcon,
  SettingsInputComponent as SettingsInputComponentIcon
} from '@mui/icons-material';
import React from 'react';
import {
  ArrayField,
  Button,
  Create,
  Datagrid,
  Edit,
  EditButton,
  ReferenceInput,
  SelectInput,
  Show,
  SimpleShowLayout,
  SimpleForm,
  TextField,
  TextInput,
  TopToolbar,
  useRecordContext,
  useGetRecordId
} from 'react-admin';
import { Link } from 'react-router-dom';

import DeleteSpaceDeviceButton from './DeleteSpaceDeviceButton';
import { handleRelated } from './utils';

export const SpaceShow = props => {
  const recordId = useGetRecordId();
  return (
    <Show actions={<SpaceShowActions />} {...props}>
      <SimpleShowLayout>
        <TextField source="name" />
        <TextField source="layout.name" />
        <ArrayField source="devices">
          <Datagrid sort={{ field: 'name', order: 'ASC' }}>
            <TextField source="name" />
            <TextField source="device" />
            <DeleteSpaceDeviceButton spaceId={recordId} />
          </Datagrid>
        </ArrayField>
        <AddDeviceButton />
      </SimpleShowLayout>
    </Show>
  );
};

const SpaceShowActions = () => {
  const record = useRecordContext();
  return (
    <TopToolbar>
      <Button
        color="primary"
        component={Link}
        startIcon={<BusinessIcon />}
        label="Organisation"
        to={`/organisations/${record?.organisation.id}/show`}
      />
      <EditButton />
    </TopToolbar>
  );
};

export const AddDeviceButton = () => {
  const record = useRecordContext();
  return (
    <Button
      variant="contained"
      component={Link}
      to={`/space-devices/create?source={"space": "${record.id}"}`}
      label="Add Device"
      title="Add Device"
    >
      <SettingsInputComponentIcon />
    </Button>
  );
};

export const SpaceEdit = props => (
  <Edit redirect="show" {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <ReferenceInput label="Layout" source="layout" reference="layouts">
        <SelectInput optionText="name" format={handleRelated} />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export const SpaceCreate = props => (
  <Create redirect="show" {...props}>
    <SimpleForm>
      <ReferenceInput
        label="Organisation"
        source="organisation"
        reference="organisations"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="name" />
      <ReferenceInput label="Layout" source="layout" reference="layouts">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export const SpaceDeviceCreate = props => {
  return (
    <Create
      redirect={(basePath, id, data) => `spaces/${data.id}/show`}
      {...props}
    >
      <SimpleForm>
        <ReferenceInput label="Space" source="space" reference="spaces">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Device" source="device" reference="devices">
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export const SpaceDeviceEdit = props => (
  <Edit redirect="show" {...props}>
    <SimpleForm>
      <ReferenceInput label="Space" source="space" reference="spaces">
        <TextInput optionText="name" disabled />
      </ReferenceInput>
      <ReferenceInput label="Device" source="device" reference="devices">
        <TextInput optionText="name" disabled />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
