import React from 'react';
import { AppBar as AdminAppBar } from 'react-admin';
import { Box, Typography } from '@mui/material';
import { AmplifySignOut } from '@aws-amplify/ui-react';

const AppBar = ({ classes, ...props }) => (
  <AdminAppBar {...props}>
    <Typography
      color="inherit"
      sx={{
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
      }}
      id="react-admin-title"
    />
    <Box sx={{ flex: 1 }} />
    <AmplifySignOut />
  </AdminAppBar>
);

export default AppBar;
