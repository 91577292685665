import { PermMedia as PermMediaIcon } from '@mui/icons-material';
import React from 'react';
import {
  required,
  BooleanField,
  BooleanInput,
  Button,
  ArrayField,
  ChipField,
  Datagrid,
  Edit,
  EditButton,
  FileField,
  FileInput,
  ReferenceArrayInput,
  SelectArrayInput,
  SingleFieldList,
  Show,
  SimpleForm,
  TabbedShowLayout,
  Tab,
  TextField,
  TextInput,
  TopToolbar,
  useRecordContext
} from 'react-admin';
import { Link } from 'react-router-dom';

import List from './List';
import { JSONField, S3ImageField } from './fields';
import { handleRelated } from './utils';

export const AddressableAssetList = props => (
  <List {...props} sort={{ field: 'name', order: 'ASC' }}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="type" />
      <ArrayField source="tags">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <TextField source="packageAssetId" />
      <TextField source="package.name" title="package" />
      <BooleanField source="visible" />
    </Datagrid>
  </List>
);

export const AddressableAssetShow = props => (
  <Show actions={<AssetShowActions />} {...props}>
    <TabbedShowLayout>
      <Tab label="Summary">
        <TextField source="name" />
        <TextField source="type" />
        <ArrayField source="tags">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ArrayField>
        <S3ImageField source="thumbnail" />
        <TextField source="packageAssetId" />
        <BooleanField source="visible" />
      </Tab>
      <Tab label="Default Config">
        <JSONField source="defaultConfig" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const AssetShowActions = () => {
  const record = useRecordContext();
  return (
    <TopToolbar>
      <Button
        color="primary"
        startIcon={<PermMediaIcon />}
        component={Link}
        to={`/addressable-packages/${record?.package.id}/show`}
        label="Package"
      />
      <EditButton />
    </TopToolbar>
  );
};

export const AddressableAssetEdit = props => (
  <Edit redirect="show" {...props}>
    <SimpleForm>
      <TextInput source="name" validate={[required()]} />
      <TextInput source="type" validate={[required()]} />
      <ReferenceArrayInput source="tags" reference="asset-tags" label="Tags">
        <SelectArrayInput optionText="name" format={handleRelated} />
      </ReferenceArrayInput>
      <FileInput
        source="defaultConfig"
        label="Default Config JSON"
        accept="application/json"
      >
        <FileField source="src" title="title" />
      </FileInput>
      <BooleanInput source="visible" />
    </SimpleForm>
  </Edit>
);
