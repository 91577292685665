import React from 'react';
import {
  Create,
  Edit,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  BooleanInput,
  TextInput
} from 'react-admin';

export const Roles = [
  { id: 'editor', name: 'Editor' },
  { id: 'admin', name: 'Admin' },
  { id: 'superuser', name: 'Superuser Admin' },
  { id: 'simulator_user', name: 'Simulator User' },
  { id: 'iris_user', name: 'Iris User' },
  { id: 'beta', name: 'Beta User' }
];

const orgRedirect = (resource, id, data) =>
  `organisations/${data.organisation}/show/1`;

export const UserEdit = props => (
  <Edit redirect={orgRedirect} {...props}>
    <SimpleForm>
      <TextInput source="email" disabled />
      <TextInput source="first_name" />
      <TextInput source="last_name" />
      <BooleanInput source="Enabled" />
      <SelectArrayInput source="roles" choices={Roles} />
    </SimpleForm>
  </Edit>
);

export const UserCreate = props => (
  <Create redirect={orgRedirect} {...props}>
    <SimpleForm>
      <ReferenceInput
        label="Organisation"
        source="organisation"
        reference="organisations"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="email" />
      <TextInput source="first_name" />
      <TextInput source="last_name" />
      <BooleanInput source="Enabled" />
      <SelectArrayInput source="roles" choices={Roles} />
    </SimpleForm>
  </Create>
);
