import React from 'react';
import {
  BooleanField,
  Button,
  Create,
  Datagrid,
  DateField,
  Edit,
  ImageField,
  ImageInput,
  ReferenceManyField,
  SelectField,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  useRecordContext
} from 'react-admin';
import { Link } from 'react-router-dom';

import List from './List';
import { S3ImageField } from './fields';
import { SurfacePosition } from './surfaces';

export const LayoutList = props => (
  <List {...props} sort={{ field: 'name', order: 'ASC' }}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <DateField source="created_at" showTime />
      <DateField source="updated_at" showTime />
    </Datagrid>
  </List>
);

export const LayoutShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <S3ImageField source="media" />
      <ReferenceManyField
        reference="surfaces"
        target="layoutId"
        label="Surfaces"
      >
        <Datagrid>
          <SelectField source="position" choices={SurfacePosition} />
          <BooleanField source="wide" />
          <TextField source="dimensions" />
          <TextField source="physicalAspectRatio" />
          <TextField source="surfaceDivisions" />
          <ShowButton />
        </Datagrid>
      </ReferenceManyField>
      <AddSurfaceButton />
    </SimpleShowLayout>
  </Show>
);

export const AddSurfaceButton = () => {
  const record = useRecordContext();
  return (
    <Button
      variant="contained"
      component={Link}
      to={`/surfaces/create?source={"layout": "${record.id}"}`}
      label="Add Surface"
    />
  );
};

export const LayoutEdit = props => (
  <Edit redirect="show" {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <ImageInput source="media" label="Picture" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);

export const LayoutCreate = props => (
  <Create redirect="show" {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" />
      <ImageInput source="media" label="Picture" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Create>
);
