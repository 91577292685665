import React from 'react';
import {
  required,
  Create,
  Datagrid,
  DateField,
  Edit,
  FileField,
  FileInput,
  ReferenceManyField,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput
} from 'react-admin';

import List from './List';
import { S3FileField } from './fields';

export const AddressablePackageList = props => (
  <List {...props} sort={{ field: 'updated_at', order: 'DESC' }}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <DateField source="created_at" showTime />
    </Datagrid>
  </List>
);

export const AddressablePackageShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <S3FileField source="file" />
      <ReferenceManyField
        reference="addressable-assets"
        target="packageId"
        label="Assets List"
      >
        <Datagrid>
          <TextField source="name" />
          <TextField source="type" />
          <TextField source="packageAssetId" />
          <ShowButton />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);

export const AddressablePackageEdit = props => (
  <Edit redirect="show" {...props}>
    <SimpleForm>
      <TextInput source="name" validate={[required()]} />
    </SimpleForm>
  </Edit>
);

export const AddressablePackageCreate = props => (
  <Create redirect="show" {...props}>
    <SimpleForm>
      <TextInput source="name" validate={[required()]} />
      <FileInput
        source="file"
        label="AddressablePackage ZIP"
        accept="application/zip"
        validate={[required()]}
      >
        <FileField source="src" title="name" />
      </FileInput>
    </SimpleForm>
  </Create>
);
