const {
  REACT_APP_IDENTITY_POOL_ID,
  REACT_APP_IMMERSIVE_INTERACTIVE_ASSETS_BUCKET,
  REACT_APP_IMMERSIVE_INTERACTIVE_AWS_API,
  REACT_APP_USER_POOL_ID,
  REACT_APP_USER_POOL_WEB_CLIENT_ID
} = process.env;

export default {
  Storage: {
    AWSS3: {
      bucket: REACT_APP_IMMERSIVE_INTERACTIVE_ASSETS_BUCKET
    }
  },
  Auth: {
    authenticationFlowType: 'USER_SRP_AUTH',
    identityPoolId: REACT_APP_IDENTITY_POOL_ID,
    mandatorySignIn: true,
    region: 'eu-west-2',
    userPoolId: REACT_APP_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_USER_POOL_WEB_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: 'api',
        region: 'eu-west-2',
        endpoint: REACT_APP_IMMERSIVE_INTERACTIVE_AWS_API
      }
    ]
  }
};
