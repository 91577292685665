import isPlainObject from 'lodash/isPlainObject';
import isArray from 'lodash/isArray';

// React admin expects an id for related fields but the server returns
// whole objects. So transforms related field from object to string id
// when necessary
export const handleRelated = related => {
  if (isArray(related)) {
    return related.map(handleRelated);
  } else if (isPlainObject(related) && related.id) {
    return related.id;
  } else {
    return related;
  }
};
