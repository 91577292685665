import React from 'react';
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  FileField,
  FileInput,
  ImageField,
  ImageInput,
  NumberField,
  NumberInput,
  SelectField,
  SelectInput,
  Show,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput
} from 'react-admin';

import List from './List';
import { JSONField } from './fields';

const ProtocolTypes = [{ id: 'dmx', name: 'DMX' }];

export const DeviceList = props => (
  <List {...props} sort={{ field: 'name', order: 'ASC' }}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <SelectField source="protocol" choices={ProtocolTypes} />
      <NumberField source="channel" />
      <NumberField source="defaultDuration" />
      <DateField source="created_at" showTime />
      <DateField source="updated_at" showTime />
    </Datagrid>
  </List>
);

export const DeviceCreate = props => (
  <Create redirect="list" {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <SelectInput source="protocol" choices={ProtocolTypes} />
      <NumberInput source="channel" />
      <NumberInput source="defaultDuration" label="Default Duration (s)" />
      <ImageInput source="thumbnail" label="Thumbnail" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>
      <FileInput
        source="settings"
        label="Settings JSON"
        accept="application/json"
      >
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
);

export const DeviceShow = props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Summary">
        <TextField source="name" />
        <SelectField source="protocol" choices={ProtocolTypes} />
        <NumberField source="channel" />
        <NumberField source="defaultDuration" label="Default Duration (s)" />
      </Tab>
      <Tab label="Settings">
        <JSONField source="settings" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const DeviceEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <SelectInput source="protocol" choices={ProtocolTypes} />
      <NumberInput source="channel" />
      <NumberInput source="defaultDuration" label="Default Duration (s)" />
      <ImageInput source="thumbnail" label="Thumbnail" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>
      <FileInput
        source="settings"
        label="Settings JSON"
        accept="application/json"
      >
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Edit>
);
