import React from 'react';
import {
  BooleanField,
  BooleanInput,
  Button,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  NumberField,
  ReferenceManyField,
  Show,
  ShowButton,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  useNotify,
  useRefresh,
  useRecordContext
} from 'react-admin';
import { Link } from 'react-router-dom';
import API from '@aws-amplify/api';
import Chip from '@mui/material/Chip';
import {
  NoEncryption as NoEncryptionIcon,
  Check,
  Close,
  VerifiedUser
} from '@mui/icons-material';
import { useConfirm } from 'material-ui-confirm';

import List from './List';
import { Roles } from './users';
import { addHeaders } from './dataProvider';

const getRole = id => {
  const r = Roles.find(role => role.id === id);
  return r ? r.name : 'Unknown role';
};

const RolesField = ({ source }) => {
  const record = useRecordContext();

  return (
    <div>
      {record[source].map(name => (
        <Chip key={name} label={getRole(name)} sx={{ m: 2 }} />
      ))}
    </div>
  );
};

export const OrganisationList = props => (
  <List {...props} sort={{ field: 'updated_at', order: 'DESC' }}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <DateField source="created_at" showTime />
      <DateField source="updated_at" showTime />
    </Datagrid>
  </List>
);

export const OrganisationShow = props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Summary">
        <TextField source="name" />

        <NumberField source="max_users" />
        <BooleanField source="require_mfa" />
        <BooleanField source="show_bespoke" />
        <ReferenceManyField
          reference="spaces"
          target="organisationId"
          label="Spaces"
        >
          <Datagrid>
            <TextField source="name" />
            <NumberField source="devices.length" label="Devices" />
            <TextField source="layout.name" label="Layout" />
            <DateField source="created_at" showTime />
            <DateField source="updated_at" showTime />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
        <AddSpaceButton />
      </Tab>
      <Tab label="Users">
        <ReferenceManyField
          perPage={60}
          reference="users"
          target="organisationId"
          label="Users list"
        >
          <Datagrid>
            <TextField source="first_name" />
            <TextField source="last_name" />
            <BooleanField source="Enabled" />
            <TextField source="email" />
            <RolesField source="roles" />
            <EditButton />
            <ResetMFAButton />
            <EnableDisableButton source="Enabled" />
            <ResetPasswordButton />
          </Datagrid>
        </ReferenceManyField>
        <AddUserButton />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const ResetMFAButton = ({ icon = <NoEncryptionIcon />, ...rest }) => {
  const confirm = useConfirm();
  const notify = useNotify();
  const record = useRecordContext();

  const handleClick = async () => {
    try {
      await confirm({ description: 'Remove MFA for this user if enabled?' });
      const url = `/admin/immersive-interactive/users/${record.id}/remove-mfa`;
      await API.post('api', url);
      notify('MFA Removed');
    } catch (err) {}
  };

  return (
    <Button label="Remove MFA" onClick={handleClick} {...rest}>
      {icon}
    </Button>
  );
};

const EnableDisableButton = ({ ...rest }) => {
  const record = useRecordContext();
  const icon = record.Enabled ? <Check /> : <Close />;
  const refresh = useRefresh();
  const notify = useNotify();

  const confirm = useConfirm();
  const handleClick = async e => {
    try {
      await confirm({
        description: `Are you sure you want to ${
          record.Enabled ? 'disable' : 'enable'
        } this user?`
      });

      const url = `/admin/immersive-interactive/users/${record.id}/${
        record.Enabled ? 'disableUser' : 'enableUser'
      }`;
      const options = {
        headers: await addHeaders()
      };
      const params = {
        UserName: record.UserName
      };
      await API.post('api', url, options, params);
      notify('User Updated');
      console.log('refresh', e);
      refresh();
    } catch (err) {}
  };

  return (
    <Button
      label={record.Enabled ? 'Disable' : 'Enable'}
      onClick={handleClick}
      {...rest}
    >
      {icon}
    </Button>
  );
};

const ResetPasswordButton = ({ icon = <VerifiedUser />, ...rest }) => {
  const notify = useNotify();
  const record = useRecordContext();

  const handleClick = async () => {
    const url = `/admin/immersive-interactive/users/${record.id}/resetUsersPassword`;
    const options = {
      headers: await addHeaders()
    };
    API.post('api', url, options);
    notify('Password Reset');
  };

  return (
    <Button label="Reset Password" onClick={handleClick} {...rest}>
      {icon}
    </Button>
  );
};

export const AddSpaceButton = () => {
  const record = useRecordContext();
  return (
    <Button
      variant="contained"
      component={Link}
      to={`/spaces/create?source={"organisation": "${record.id}"}`}
      label="Add Space"
      title="Add Space"
    />
  );
};

export const AddUserButton = () => {
  const record = useRecordContext();
  return (
    <Button
      variant="contained"
      component={Link}
      to={`/users/create?source={"organisation": "${record.id}"}`}
      label="Add User"
      title="Add User"
    />
  );
};

export const OrganisationEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="max_users" />
      <BooleanInput source="require_mfa" />
      <BooleanInput source="show_bespoke" />
    </SimpleForm>
  </Edit>
);

export const OrganisationCreate = props => (
  <Create redirect="show" {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <BooleanInput source="require_mfa" />
      <BooleanInput source="show_bespoke" />
    </SimpleForm>
  </Create>
);
