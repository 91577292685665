import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';
import queryString from 'query-string';
import simpleRestProvider from 'ra-data-simple-rest';

import { uploadMedia } from './s3.js';
import { handleRelated } from './utils';
import AWS from 'aws-sdk';

const { REACT_APP_IMMERSIVE_INTERACTIVE_AWS_API } = process.env;
const { REACT_APP_IMMERSIVE_INTERACTIVE_ASSETS_BUCKET } = process.env;

const isLocal =
  REACT_APP_IMMERSIVE_INTERACTIVE_AWS_API.indexOf('localhost') > -1;

const handleRelatedArrays = data => {
  return Object.keys(data).reduce((obj, key) => {
    obj[key] = handleRelated(data[key]);
    return obj;
  }, {});
};

const replaceRelatedWithIds = ({ data, ...params }) => ({
  ...params,
  data: handleRelatedArrays(data)
});

const withTemplateUploader = provider => {
  const createHandlers = {
    assets: assetsCreate,
    themes: themesCreate,
    devices: devicesCreate,
    layouts: layoutsCreate,
    templates: templatesCreate,
    'template-files': templatesFilesCreate,
    'addressable-packages': packageFileCreate
  };

  const updateHandlers = {
    assets: assetsUpdate,
    themes: themesUpdate,
    devices: devicesUpdate,
    layouts: layoutsUpdate,
    templates: templatesUpdate,
    'template-files': templatesFilesUpdate,
    'addressable-assets': addressabeAssetsFileUpdate
  };

  return {
    ...provider,
    create: async (resource, _params) => {
      const params = replaceRelatedWithIds(_params);
      const handler = createHandlers[resource];
      const updatedParams = handler ? await handler(params) : params;
      return provider.create(resource, updatedParams);
    },
    update: async (resource, _params) => {
      const params = replaceRelatedWithIds(_params);
      const handler = updateHandlers[resource];
      const updatedParams = handler ? await handler(params) : params;
      return provider.update(resource, updatedParams);
    }
  };
};

const layoutsCreate = async params => {
  let {
    data: { media, ...values }
  } = params;
  let mediaS3 = await uploadMedia(media.rawFile);
  return {
    data: {
      media: mediaS3.id,
      ...values
    }
  };
};

const layoutsUpdate = async params => {
  let {
    data: { media, ...values }
  } = params;
  if (media.rawFile) {
    let mediaS3 = await uploadMedia(media.rawFile);
    values.media = mediaS3.id;
  }
  return {
    ...params,
    data: values
  };
};

const assetsCreate = async params => {
  let {
    data: { media, ...values }
  } = params;

  let mediaS3 = await uploadMedia(media.rawFile);

  return {
    data: {
      ...values,
      media: mediaS3.id
    }
  };
};

const themesUpdate = async params => {
  let {
    data: { json, media, ...values }
  } = params;

  if (media && media.rawFile) {
    let mediaS3 = await uploadMedia(media.rawFile);
    values.media = mediaS3.id;
  }
  if (json.rawFile) {
    let settingsFile = await readFile(json.rawFile);
    values.json = JSON.parse(settingsFile);
  }

  return {
    ...params,
    data: values
  };
};

const themesCreate = async params => {
  let {
    data: { json, media, ...values }
  } = params;

  if (media && media.rawFile) {
    values.media = await uploadMedia(media.rawFile);
  }
  let jsonFile = await readFile(json.rawFile);

  return {
    data: {
      ...values,
      json: JSON.parse(jsonFile)
    }
  };
};

const assetsUpdate = async params => {
  let {
    data: { media, ...values }
  } = params;

  if (media.rawFile) {
    let mediaS3 = await uploadMedia(media.rawFile);
    values.media = mediaS3.id;
  }

  return {
    ...params,
    data: values
  };
};

const templatesCreate = async params => {
  let {
    data: { file, defaultConfig, thumbnail, ...values }
  } = params;

  if (file) {
    let fileS3 = await uploadMedia(file.rawFile);
    values.file = fileS3.id;
  }

  let thumbnailS3 = await uploadMedia(thumbnail.rawFile);
  let defaultConfigFile = await readFile(defaultConfig.rawFile);

  return {
    data: {
      ...values,
      defaultConfig: JSON.parse(defaultConfigFile),
      thumbnail: thumbnailS3.id
    }
  };
};

const templatesUpdate = async params => {
  let {
    data: { defaultConfig, thumbnail, templateFile, ...values }
  } = params;

  values.templateFile = templateFile === '' ? null : templateFile;

  let { file } = values;
  if (file && file.rawFile) {
    let fileS3 = await uploadMedia(file.rawFile);
    values.file = fileS3.id;
  }

  if (thumbnail.rawFile) {
    let thumbnailS3 = await uploadMedia(thumbnail.rawFile);
    values.thumbnail = thumbnailS3.id;
  }
  if (defaultConfig.rawFile) {
    let config = await readFile(defaultConfig.rawFile);
    values.defaultConfig = JSON.parse(config);
  }

  return {
    ...params,
    data: values
  };
};

const templatesFilesCreate = async params => {
  let {
    data: { file, ...values }
  } = params;

  if (file) {
    let fileS3 = await uploadMedia(file.rawFile);
    values.file = fileS3.id;
  }

  return {
    ...params,
    data: values
  };
};

const templatesFilesUpdate = async params => {
  let {
    data: { file, ...values }
  } = params;

  if (file && file.rawFile) {
    let fileS3 = await uploadMedia(file.rawFile);
    values.file = fileS3.id;
  }

  return {
    ...params,
    data: values
  };
};

const packageFileCreate = async params => {
  let {
    data: { file, ...values }
  } = params;
  let credentials = await Auth.currentUserCredentials();

  const s3 = new AWS.S3({
    signatureVersion: 'v4',
    region: 'eu-west-2',
    ...credentials
  });

  const x = await new Promise((resolve, reject) => {
    return s3
      .upload({
        Bucket: REACT_APP_IMMERSIVE_INTERACTIVE_ASSETS_BUCKET,
        Key: `addressables/temp/${file.title}`,
        Body: file.rawFile
      })
      .send((err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
  });

  values.s3File = x;

  return {
    ...params,
    data: values
  };
};

const convertFileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file.rawFile);
  });

const addressabeAssetsFileUpdate = async params => {
  let {
    data: { defaultConfig, ...values }
  } = params;

  if (defaultConfig?.rawFile) {
    let config = await readFile(defaultConfig.rawFile);
    values.defaultConfig = JSON.parse(config);
  }

  return {
    ...params,
    data: values
  };
};

const devicesCreate = async params => {
  let {
    data: { settings, thumbnail, ...values }
  } = params;

  let thumbnailS3 = await uploadMedia(thumbnail.rawFile);
  let settingsFile = await readFile(settings.rawFile);

  return {
    data: {
      ...values,
      settings: JSON.parse(settingsFile),
      thumbnail: thumbnailS3.id
    }
  };
};

const devicesUpdate = async params => {
  let {
    data: { settings, thumbnail, ...values }
  } = params;

  if (thumbnail.rawFile) {
    let thumbnailS3 = await uploadMedia(thumbnail.rawFile);
    values.thumbnail = thumbnailS3.id;
  }
  if (settings.rawFile) {
    let settingsFile = await readFile(settings.rawFile);
    values.settings = JSON.parse(settingsFile);
  }

  return {
    ...params,
    data: values
  };
};

const readFile = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

const baseUrl = '/admin/immersive-interactive';
const provider = simpleRestProvider(
  baseUrl,
  async (url, { method = 'GET', body } = {}) => {
    let [endpoint, queryStringPart] = url.split('?');
    let options = {
      response: true,
      headers: await addHeaders()
    };

    if (['PUT', 'POST'].includes(method)) {
      options = {
        ...options,
        body: JSON.parse(body || '{}')
      };
    } else if (['GET', 'DELETE'].includes(method)) {
      options = {
        ...options,
        queryStringParameters: queryString.parse(queryStringPart)
      };
    }

    let apiMethod = method.toLowerCase();
    if (apiMethod === 'delete') {
      apiMethod = 'del';
    }
    let { headers, data } = await API[apiMethod]('api', endpoint, options);

    let response = {
      headers: new Headers(headers),
      json: data
    };
    return response;
  }
);

export default withTemplateUploader(provider);
export async function addHeaders(headers = {}) {
  if (isLocal) {
    const cred = await Auth.currentAuthenticatedUser();
    headers = {
      ...headers,
      'immersive-interactive-cognito-authentication-provider': `CognitoSignIn:${cred.username}`,
      'immersive-Interactive-cognito-identity-id': cred.username,
      'immersive-Interactive-source-ip': '127.0.0.1'
    };
  }
  return headers;
}
