import { ViewCarousel as ViewCarouselIcon } from '@mui/icons-material';
import React from 'react';
import {
  BooleanField,
  BooleanInput,
  Button,
  Create,
  Edit,
  EditButton,
  ReferenceInput,
  SelectField,
  SelectInput,
  Show,
  SimpleShowLayout,
  SimpleForm,
  TextField,
  TextInput,
  TopToolbar,
  useRecordContext,
  NumberInput
} from 'react-admin';
import { Link } from 'react-router-dom';

export const SurfacePosition = [
  { id: 1, name: 'Left' },
  { id: 2, name: 'Center' },
  { id: 4, name: 'Right' },
  { id: 8, name: 'Back' },
  { id: 16, name: 'Floor' }
  //{ id: 7, name: 'Walls' }
  //{ id: 3, name: 'Left Center' },
  //{ id: 6, name: 'Center Right' }
];

export const SurfaceShow = props => (
  <Show actions={<SurfaceShowActions />} {...props}>
    <SimpleShowLayout>
      <SelectField source="position" choices={SurfacePosition} />
      <TextField source="dimensions" />
      <BooleanField source="wide" />
      <TextField source="physicalAspectRatio" />
      <TextField source="surfaceDivisions" />
    </SimpleShowLayout>
  </Show>
);

const SurfaceShowActions = ({ basePath, data, resource }) => {
  const record = useRecordContext();
  return (
    <TopToolbar>
      <Button
        color="primary"
        to={`/layouts/${record?.layout.id}/show`}
        component={Link}
        startIcon={<ViewCarouselIcon />}
        label="Layout"
      />
      <EditButton />
    </TopToolbar>
  );
};

const layoutRedirect = (resource, id, data) => `layouts/${data.layout.id}/show`;

export const SurfaceEdit = props => (
  <Edit redirect={layoutRedirect} {...props}>
    <SimpleForm>
      <SelectInput source="position" choices={SurfacePosition} />
      <TextInput source="dimensions" />
      <BooleanInput source="wide" />{' '}
      <NumberInput source="physicalAspectRatio" />
      <NumberInput source="surfaceDivisions" />
    </SimpleForm>
  </Edit>
);

const layoutCreateRedirect = (resource, id, data) =>
  `layouts/${data.layout.id}/show`;

export const SurfaceCreate = props => (
  <Create redirect={layoutCreateRedirect} {...props}>
    <SimpleForm>
      <ReferenceInput label="Layout" source="layout" reference="layouts">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <SelectInput source="position" choices={SurfacePosition} />
      <TextInput source="dimensions" />
      <BooleanInput source="wide" defaultValue={false} />
      <NumberInput source="physicalAspectRatio" />
      <NumberInput source="surfaceDivisions" />
    </SimpleForm>
  </Create>
);
