import {
  Business as BusinessIcon,
  DonutSmall as DonutSmallIcon,
  LocalOffer as LocalOfferIcon,
  Movie as MovieIcon,
  Palette as PaletteIcon,
  PermMedia as PermMediaIcon,
  ViewCarousel as ViewCarouselIcon,
  SettingsInputSvideo,
  ViewComfy,
  AccountBalance,
  Build,
  Attachment,
  Label as LabelIcon
} from '@mui/icons-material';
import React from 'react';
import { Admin, Resource } from 'react-admin';
import Amplify from '@aws-amplify/core';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import { ConfirmProvider } from 'material-ui-confirm';

import { AssetCreate, AssetEdit, AssetList, AssetShow } from './assets';
import {
  AddressableAssetEdit,
  AddressableAssetShow,
  AddressableAssetList
} from './addressableAsset';
import {
  AddressablePackageCreate,
  AddressablePackageEdit,
  AddressablePackageList,
  AddressablePackageShow
} from './addressablePackage';
import { CategoryEdit, CategoryCreate, CategoryList } from './categories';
import { DeviceList, DeviceEdit, DeviceCreate, DeviceShow } from './devices';
import { LayoutList, LayoutEdit, LayoutCreate, LayoutShow } from './layouts';
import { MetaEdit, MetaCreate, MetaList } from './meta';
import {
  OrganisationEdit,
  OrganisationCreate,
  OrganisationList,
  OrganisationShow
} from './organisations';
import {
  SpaceCreate,
  SpaceDeviceCreate,
  SpaceDeviceEdit,
  SpaceEdit,
  SpaceShow
} from './spaces';
import { SurfaceCreate, SurfaceEdit, SurfaceShow } from './surfaces';
import {
  TemplateCreate,
  TemplateEdit,
  TemplateList,
  TemplateShow
} from './templates';
import {
  TemplateFileCreate,
  TemplateFileEdit,
  TemplateFileList,
  TemplateFileShow
} from './templateFiles';
import { ThemeCreate, ThemeEdit, ThemeList, ThemeShow } from './themes';
import { EventList, EventShow } from './events';
import { UserCreate, UserEdit } from './users';
import { ExperienceList } from './experiences';
import { CollectionList } from './collections';
import Layout from './Layout';

import awsConfig from './awsConfig';
import dataProvider from './dataProvider';
Amplify.configure(awsConfig);

const App = () => (
  <AmplifyAuthenticator>
    <AmplifySignIn hideSignUp slot="sign-in"></AmplifySignIn>
    <ConfirmProvider>
      <Admin dataProvider={dataProvider} layout={Layout}>
        <Resource
          name="organisations"
          icon={BusinessIcon}
          create={OrganisationCreate}
          edit={OrganisationEdit}
          list={OrganisationList}
          show={OrganisationShow}
        />
        <Resource
          name="templates"
          icon={Build}
          create={TemplateCreate}
          edit={TemplateEdit}
          list={TemplateList}
          show={TemplateShow}
        />
        <Resource
          name="template-files"
          icon={Attachment}
          create={TemplateFileCreate}
          edit={TemplateFileEdit}
          list={TemplateFileList}
          show={TemplateFileShow}
        />
        <Resource
          name="experiences"
          icon={AccountBalance}
          // create={TemplateCreate}
          // edit={TemplateEdit}
          list={ExperienceList}
          // show={TemplateShow}
        />

        <Resource
          name="collections"
          icon={ViewComfy}
          // create={TemplateCreate}
          // edit={TemplateEdit}
          list={CollectionList}
          // show={TemplateShow}
        />
        <Resource
          name="assets"
          icon={PermMediaIcon}
          create={AssetCreate}
          edit={AssetEdit}
          list={AssetList}
          show={AssetShow}
        />
        <Resource
          name="addressable-packages"
          icon={PermMediaIcon}
          create={AddressablePackageCreate}
          edit={AddressablePackageEdit}
          list={AddressablePackageList}
          show={AddressablePackageShow}
        />
        <Resource
          name="addressable-assets"
          icon={PermMediaIcon}
          list={AddressableAssetList}
          edit={AddressableAssetEdit}
          show={AddressableAssetShow}
        />
        <Resource
          name="themes"
          icon={PaletteIcon}
          create={ThemeCreate}
          edit={ThemeEdit}
          list={ThemeList}
          show={ThemeShow}
        />
        <Resource name="users" create={UserCreate} edit={UserEdit} />
        <Resource
          name="spaces"
          create={SpaceCreate}
          edit={SpaceEdit}
          show={SpaceShow}
        />
        <Resource
          name="space-devices"
          create={SpaceDeviceCreate}
          edit={SpaceDeviceEdit}
        />
        <Resource
          name="surfaces"
          create={SurfaceCreate}
          edit={SurfaceEdit}
          show={SurfaceShow}
        />
        <Resource
          name="layouts"
          icon={ViewCarouselIcon}
          create={LayoutCreate}
          edit={LayoutEdit}
          list={LayoutList}
          show={LayoutShow}
        />
        <Resource
          name="devices"
          icon={SettingsInputSvideo}
          create={DeviceCreate}
          edit={DeviceEdit}
          list={DeviceList}
          show={DeviceShow}
        />
        <Resource
          name="categories"
          create={CategoryCreate}
          edit={CategoryEdit}
          list={CategoryList}
        />
        <Resource
          name="experience-types"
          icon={MovieIcon}
          create={MetaCreate}
          edit={MetaEdit}
          list={MetaList}
        />
        <Resource
          name="sectors"
          icon={DonutSmallIcon}
          create={MetaCreate}
          edit={MetaEdit}
          list={MetaList}
        />
        <Resource
          name="tags"
          icon={LocalOfferIcon}
          create={MetaCreate}
          edit={MetaEdit}
          list={MetaList}
        />
        <Resource
          name="asset-tags"
          icon={LocalOfferIcon}
          create={MetaCreate}
          edit={MetaEdit}
          list={MetaList}
        />
        <Resource
          name="attributes"
          icon={LabelIcon}
          create={MetaCreate}
          edit={MetaEdit}
          list={MetaList}
        />
        <Resource
          name="events"
          icon={LabelIcon}
          list={EventList}
          show={EventShow}
        />
      </Admin>
    </ConfirmProvider>
  </AmplifyAuthenticator>
);

export default App;
