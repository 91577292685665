import React from 'react';
import { Datagrid, DateField, TextField } from 'react-admin';

import List from './List';

export const ExperienceList = props => (
  <List {...props} sort={{ field: 'updated_at', order: 'DESC' }}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
      <DateField source="created_at" showTime />
      <DateField source="updated_at" showTime />
    </Datagrid>
  </List>
);
