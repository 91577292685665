import React from 'react';
import {
  required,
  Create,
  Datagrid,
  DateField,
  Edit,
  FileField,
  FileInput,
  ImageField,
  ImageInput,
  SelectField,
  SelectInput,
  Show,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput
} from 'react-admin';

import List from './List';
import { S3ImageField, JSONField } from './fields';

export const Privacy = [
  { id: 1, name: 'Personal' },
  { id: 2, name: 'Organisation' },
  { id: 3, name: 'Public' }
];

export const ThemeList = props => (
  <List {...props} sort={{ field: 'updated_at', order: 'DESC' }}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <SelectField source="privacy" choices={Privacy} />
      <TextField
        source="organisation.name"
        label="Organisation"
        sortable={false}
      />
      <TextField source="sub.given_name" label="First Name" sortable={false} />
      <TextField source="sub.family_name" label="Last Name" sortable={false} />
      <DateField source="created_at" showTime />
      <DateField source="updated_at" showTime />
    </Datagrid>
  </List>
);

export const ThemeShow = props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Summary">
        <TextField source="name" />
        <SelectField source="privacy" choices={Privacy} />
        <S3ImageField source="media" />
      </Tab>
      <Tab label="Theme JSON">
        <JSONField source="json" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const ThemeEdit = props => (
  <Edit redirect="show" {...props}>
    <SimpleForm>
      <TextInput source="name" validate={[required()]} />
      <SelectInput source="privacy" choices={Privacy} validate={[required()]} />
      <ImageInput source="media" label="Cover" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>
      <FileInput
        source="json"
        label="Theme JSON"
        accept="application/json"
        validate={[required()]}
      >
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Edit>
);

export const ThemeCreate = props => (
  <Create redirect="show" {...props}>
    <SimpleForm>
      <TextInput source="name" validate={[required()]} />
      <SelectInput source="privacy" choices={Privacy} validate={[required()]} />
      <ImageInput source="media" label="Cover" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>
      <FileInput
        source="json"
        label="Theme JSON"
        accept="application/json"
        validate={[required()]}
      >
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
);
