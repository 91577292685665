import React from 'react';
import JSONPretty from 'react-json-pretty';
import JSONPrettyMon from 'react-json-pretty/dist/monikai';
import { useRecordContext } from 'react-admin';

import { useSignedUrl } from './s3.js';

export const S3ImageField = ({ source }) => {
  const record = useRecordContext();
  let url = record[source];
  let signedUrl = useSignedUrl(url);
  const style = {
    maxHeight: 300,
    maxWidth: 300
  };
  return signedUrl && <img src={signedUrl} style={style} alt="" />;
};

S3ImageField.defaultProps = {
  addLabel: true
};

export const S3FileField = ({ source }) => {
  const record = useRecordContext();
  let url = record[source];
  let signedUrl = useSignedUrl(url);
  return (
    signedUrl && (
      <a href={signedUrl} target="_blank" download rel="noopener noreferrer">
        {url.original_filename || 'Download'}
      </a>
    )
  );
};

S3FileField.defaultProps = {
  addLabel: true
};

export const JSONField = ({ source, ...props }) => {
  const record = useRecordContext();
  let json = record[source];
  return <JSONPretty data={json} theme={JSONPrettyMon} />;
};

JSONField.defaultProps = {
  addLabel: true
};
