import React from 'react';
import {
  useRecordContext,
  DateField,
  Datagrid,
  Show,
  SimpleShowLayout,
  TextField
} from 'react-admin';
import { Link } from 'react-router-dom';

import List from './List';
import { JSONField } from './fields';

const TextLinkField = ({ linkTo, linkFrom, ...props }) => {
  const record = useRecordContext();
  const showPage = record[linkFrom]
    ? `/${linkTo}/${record[linkFrom].id}/show`
    : undefined;

  return (
    <Link to={showPage}>
      <TextField {...props} />
    </Link>
  );
};

export const EventList = props => (
  <List {...props} sort={{ field: 'created_at', order: 'DESC' }}>
    <Datagrid rowClick="show">
      <DateField source="created_at" showTime />
      <TextField source="eventType" />
      <TextField source="experience.name" sortable={false} />
      <TextField source="organisation.name" sortable={false} />
      <TextField source="user.sub" sortable={false} />
    </Datagrid>
  </List>
);

export const EventShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <DateField source="created_at" showTime />
      <TextField source="eventType" />
      <JSONField source="eventData" />
      <TextField source="experience.name" />
      <TextLinkField
        linkTo="organisations"
        linkFrom="organisation"
        source="organisation.name"
      />
      <TextField source="user.given_name" />
      <TextField source="user.family_name" />
    </SimpleShowLayout>
  </Show>
);
