import AWS from 'aws-sdk';
import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { addHeaders } from './dataProvider';

const { REACT_APP_IMMERSIVE_INTERACTIVE_ASSETS_BUCKET } = process.env;

let cachedCredentials = null;
let cachedPromise = null;

export const useSignedUrl = url => {
  let [signedUrl, setSignedUrl] = useState('');

  const getSetSignedUrl = async filename => {
    let url = await getSignedUrlWithoutHook(filename);
    setSignedUrl(url);
  };

  useEffect(() => {
    if (url && url.filename) {
      getSetSignedUrl(url.filename);
    }
  }, [url]);

  return signedUrl;
};

const credentialsExpired = credentials => {
  let now = moment();
  let expiration = moment(credentials.Expiration);
  let expiredRecently = expiration.subtract(5, 'minute') < now;
  return expiredRecently;
};

const getSignedUrlWithoutHook = async filename => {
  let credentials;
  if (cachedCredentials && !credentialsExpired(cachedCredentials)) {
    credentials = cachedCredentials;
  } else if (cachedPromise) {
    credentials = await cachedPromise;
  } else {
    cachedCredentials = null;
    cachedPromise = Auth.currentUserCredentials();
    credentials = await cachedPromise;
    cachedCredentials = credentials;
  }

  const s3 = new AWS.S3({
    signatureVersion: 'v4',
    region: 'eu-west-2',
    ...credentials
  });

  const params = {
    Bucket: REACT_APP_IMMERSIVE_INTERACTIVE_ASSETS_BUCKET,
    Key: filename
  };
  const url = s3.getSignedUrl('getObject', params);

  return url;
};

export const uploadMedia = async file => {
  let credentials = await Auth.currentUserCredentials();
  let headers = await addHeaders();
  const media = await API.post('api', '/media', {
    body: {
      name: file.name
    },
    headers
  });

  const s3 = new AWS.S3({
    signatureVersion: 'v4',
    region: 'eu-west-2',
    ...credentials
  });

  return new Promise((resolve, reject) => {
    s3.upload({
      Bucket: REACT_APP_IMMERSIVE_INTERACTIVE_ASSETS_BUCKET,
      Key: media.filename,
      Body: file
    }).send((err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(media);
      }
    });
  });
};
