import React from 'react';
import {
  required,
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  FileField,
  FileInput,
  ImageField,
  ImageInput,
  ReferenceInput,
  SelectField,
  SelectInput,
  Show,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput
} from 'react-admin';

import List from './List';
import { S3FileField, S3ImageField, JSONField } from './fields';
import { handleRelated } from './utils';

const TemplateTypes = [
  { id: 'standard', name: 'Standard' },
  { id: 'media', name: 'Media' },
  { id: 'bespoke', name: 'Bespoke' }
];

export const TemplateList = props => (
  <List {...props} sort={{ field: 'updated_at', order: 'DESC' }}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="version" />
      <TextField source="editorDirectory" />
      <SelectField source="type" choices={TemplateTypes} />
      <BooleanField source="hasHotspots" />
      <TextField
        source="templateFile.name"
        label="Template File"
        sortable={false}
      />
      <DateField source="created_at" showTime />
      <DateField source="updated_at" showTime />
      <BooleanField source="disabled" />
      <BooleanField source="featured" />
    </Datagrid>
  </List>
);

export const TemplateCreate = props => (
  <Create redirect="list" {...props}>
    <SimpleForm>
      <TextInput source="name" validate={[required()]} />
      <TextInput source="version" />
      <TextInput
        source="editorDirectory"
        helperText="Absolute path e.g. /unity-editor/"
      />
      <SelectInput
        source="type"
        choices={TemplateTypes}
        validate={[required()]}
      />
      <BooleanInput source="hasHotspots" />
      <ImageInput
        source="thumbnail"
        label="Thumbnail"
        accept="image/*"
        validate={[required()]}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <ReferenceInput
        label="Template File"
        reference="template-files"
        source="templateFile"
        perPage={100}
      >
        <SelectInput optionText="name" format={handleRelated} />
      </ReferenceInput>
      <FileInput source="file" label="File">
        <FileField source="src" title="title" />
      </FileInput>
      <FileInput
        source="defaultConfig"
        label="Default Config JSON"
        accept="application/json"
        validate={[required()]}
      >
        <FileField source="src" title="title" />
      </FileInput>
      <BooleanInput source="disabled" />
      <BooleanInput source="featured" />
    </SimpleForm>
  </Create>
);

export const TemplateShow = props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Summary">
        <TextField source="name" />
        <TextField source="version" />
        <TextField source="editorDirectory" />
        <SelectField source="type" choices={TemplateTypes} />
        <BooleanField source="hasHotspots" />
        <S3ImageField source="thumbnail" />
        <TextField source="templateFile.name" label="Template File" />
        <S3FileField source="file" />
        <BooleanField source="disabled" />
        <BooleanField source="featured" />
      </Tab>
      <Tab label="Default Config">
        <JSONField source="defaultConfig" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const TemplateEdit = props => (
  <Edit redirect="show" {...props}>
    <SimpleForm>
      <TextInput source="name" validate={[required()]} />
      <TextInput source="version" />
      <TextInput
        source="editorDirectory"
        helperText="Absolute path e.g. /unity-editor/"
      />
      <SelectInput
        source="type"
        choices={TemplateTypes}
        validate={[required()]}
      />
      <BooleanInput source="hasHotspots" />
      <ImageInput
        source="thumbnail"
        label="Thumbnail"
        accept="image/*"
        validate={[required()]}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <ReferenceInput
        label="Template File"
        reference="template-files"
        source="templateFile"
        perPage={100}
      >
        <SelectInput optionText="name" format={handleRelated} />
      </ReferenceInput>
      <FileInput source="file" label="Template File Direct">
        <FileField source="src" title="title" />
      </FileInput>
      <FileInput
        source="defaultConfig"
        label="Default Config JSON"
        accept="application/json"
        validate={[required()]}
      >
        <FileField source="src" title="title" />
      </FileInput>
      <BooleanInput source="disabled" />
      <BooleanInput source="featured" />
    </SimpleForm>
  </Edit>
);
